import React, { useState } from "react";
import { Input, Select, Switch, Button, Table, message } from "antd";
import "./AddUserForm.css";

const { Option } = Select;

const AddUserForm = () => {
  const [userData, setUserData] = useState({
    userName: "",
    contactNo: "",
    role: "",
    userId: "",
    password: "",
    active: false,
  });

  const [users, setUsers] = useState([
    {
      userName: "Gargi Foods Nashik",
      contactNo: "9819780525",
      userId: "gargifoods",
      role: "Distributor",
      status: "Active",
    },
  ]);

  const handleChange = (name, value) => {
    if (name === "userName" && !/^[A-Za-z ]*$/.test(value)) {
      message.error("User Name must contain only letters and spaces.");
      return;
    }
    if (name === "contactNo" && !/^[0-9]{0,10}$/.test(value)) {
      return;
    }
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (!userData.userName || !userData.contactNo || !userData.role || !userData.userId || !userData.password) {
      message.error("All fields are required!");
      return;
    }
    if (userData.contactNo.length !== 10) {
      message.error("User Contact No. must be exactly 10 digits.");
      return;
    }
    setUsers([
      ...users,
      { ...userData, status: userData.active ? "Active" : "Inactive" },
    ]);
    setUserData({ userName: "", contactNo: "", role: "", userId: "", password: "", active: false });
    message.success("User added successfully!");
  };

  const columns = [
    { title: "User Name", dataIndex: "userName", key: "userName" },
    { title: "User Contact No", dataIndex: "contactNo", key: "contactNo" },
    { title: "User ID", dataIndex: "userId", key: "userId" },
    { title: "Role", dataIndex: "role", key: "role" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Action",
      key: "action",
      render: () => <Button type="link">Edit</Button>,
    },
  ];

  return (
    <div className="container">
      <div className="header">User Management</div>
      <div className="form-container">
        <div className="form-group">
          <label>User Name</label>
          <Input name="userName" value={userData.userName} onChange={(e) => handleChange("userName", e.target.value)} />
        </div>
        <div className="form-group">
          <label>User Contact No.</label>
          <Input name="contactNo" value={userData.contactNo} onChange={(e) => handleChange("contactNo", e.target.value)} />
        </div>
        <div className="form-group">
          <label>Role</label>
          <Select name="role" value={userData.role} onChange={(value) => handleChange("role", value)} style={{ width: "100%" }}>
            <Option value="">Select</Option>
            <Option value="Admin">Admin</Option>
            <Option value="Distributor">Distributor</Option>
          </Select>
        </div>
        <div className="form-group">
          <label>User ID</label>
          <Input name="userId" value={userData.userId} onChange={(e) => handleChange("userId", e.target.value)} />
        </div>
        <div className="form-group">
          <label>User Password</label>
          <Input.Password name="password" value={userData.password} onChange={(e) => handleChange("password", e.target.value)} />
        </div>
        <div className="form-group checkbox">
          <label className="checkbox-label">
            Active <Switch checked={userData.active} onChange={(checked) => handleChange("active", checked)} />
          </label>
        </div>
        <div className="buttons">
          <Button type="primary" onClick={handleSave} className="save-btn">Save</Button>
          <Button onClick={() => setUserData({ userName: "", contactNo: "", role: "", userId: "", password: "", active: false })} className="reset-btn">Reset</Button>
        </div>
      </div>
      <div className="table-container">
        <div className="table-header">View User</div>
        <Table dataSource={users} columns={columns} rowKey="userId" />
      </div>
    </div>
  );
};

export default AddUserForm;
