import React, { useState } from "react";
import { Input, Select, Switch, Button, Table, message } from "antd";

const { Option } = Select;

const VendorManagement = () => {
  const [distributorData, setDistributorData] = useState({
    distributorName: "",
    userId: "",
    contactNo: "",
    address: "",
    state: "",
    gstin: "",
    active: false,
  });

  const [distributors, setDistributors] = useState([
    {
      distributorName: "Gargi Foods Nashik",
      userId: "gargifoods",
      contactNo: "9819780525",
      address: "Nashik",
      state: "MH",
      gstin: "27AICPG4564F1Z1",
      status: "Active",
    },
  ]);

  const handleChange = (name, value) => {
    if (name === "gstin" && value.length > 15) return;
    setDistributorData({
      ...distributorData,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (!distributorData.distributorName || !distributorData.address || !distributorData.state || !distributorData.gstin) {
      message.error("All fields are required!");
      return;
    }
    if (distributorData.gstin.length !== 15) {
      message.error("GSTIN must be 15 characters long");
      return;
    }

    setDistributors([...distributors, { ...distributorData, status: distributorData.active ? "Active" : "Inactive" }]);
    setDistributorData({ distributorName: "", userId: "", contactNo: "", address: "", state: "", gstin: "", active: false });
    message.success("Distributor added successfully!");
  };

  const columns = [
    { title: "Distributor Name", dataIndex: "distributorName", key: "distributorName" },
    { title: "User ID", dataIndex: "userId", key: "userId" },
    { title: "Contact No", dataIndex: "contactNo", key: "contactNo" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "GSTIN No", dataIndex: "gstin", key: "gstin" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Address", dataIndex: "address", key: "address", render: (text) => <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{text}</div> },
    {
      title: "Action",
      key: "action",
      render: () => <Button type="link">Edit</Button>,
    },
  ];

  return (
    <div className="container">
      <div className="header">Distributor Management</div>
      <div className="form-container">
        <div className="form-group">
          <label>Distributor Name</label>
          <Select name="distributorName" value={distributorData.distributorName} onChange={(value) => handleChange("distributorName", value)} style={{ width: "100%" }}>
            <Option value="">Select Distributor Name</Option>
            <Option value="Gargi Foods Nashik">Gargi Foods Nashik</Option>
          </Select>
        </div>
        <div className="form-group">
          <label>User ID</label>
          <Input disabled value="Gargi Foods (data only for testing) " />
        </div>
        <div className="form-group">
          <label>Contact No.</label>
          <Input name="contactNo" disabled value="98139780526" />

        </div>
        <div className="form-group">
          <label>Distributor Address</label>
          <Input.TextArea name="address" value={distributorData.address} onChange={(e) => handleChange("address", e.target.value)} autoSize={{ minRows: 2, maxRows: 6 }} />
        </div>
        <div className="form-group">
          <label>State</label>
          <Select name="state" value={distributorData.state} onChange={(value) => handleChange("state", value)} style={{ width: "100%" }}>
            <Option value="">Select State</Option>
  <Option value="AP">Andhra Pradesh</Option>
  <Option value="AR">Arunachal Pradesh</Option>
  <Option value="AS">Assam</Option>
  <Option value="BR">Bihar</Option>
  <Option value="CG">Chhattisgarh</Option>
  <Option value="GA">Goa</Option>
  <Option value="GJ">Gujarat</Option>
  <Option value="HR">Haryana</Option>
  <Option value="HP">Himachal Pradesh</Option>
  <Option value="JH">Jharkhand</Option>
  <Option value="KA">Karnataka</Option>
  <Option value="KL">Kerala</Option>
  <Option value="MP">Madhya Pradesh</Option>
  <Option value="MH">Maharashtra</Option>
  <Option value="MN">Manipur</Option>
  <Option value="ML">Meghalaya</Option>
  <Option value="MZ">Mizoram</Option>
  <Option value="NL">Nagaland</Option>
  <Option value="OD">Odisha</Option>
  <Option value="PB">Punjab</Option>
  <Option value="RJ">Rajasthan</Option>
  <Option value="SK">Sikkim</Option>
  <Option value="TN">Tamil Nadu</Option>
  <Option value="TG">Telangana</Option>
  <Option value="TR">Tripura</Option>
  <Option value="UP">Uttar Pradesh</Option>
  <Option value="UK">Uttarakhand</Option>
  <Option value="WB">West Bengal</Option>
  <Option value="AN">Andaman and Nicobar Islands</Option>
  <Option value="CH">Chandigarh</Option>
  <Option value="DN">Dadra and Nagar Haveli and Daman and Diu</Option>
  <Option value="DL">Delhi</Option>
  <Option value="JK">Jammu and Kashmir</Option>
  <Option value="LA">Ladakh</Option>
  <Option value="LD">Lakshadweep</Option>
  <Option value="PY">Puducherry</Option>
          </Select>
        </div>
        <div className="form-group">
          <label>GSTIN No.</label>
          <Input name="gstin" value={distributorData.gstin} onChange={(e) => handleChange("gstin", e.target.value)} maxLength={15} />
        </div>
        <div className="form-group checkbox">
          <label className="checkbox-label">
            Distributor Active <Switch checked={distributorData.active} onChange={(checked) => handleChange("active", checked)} />
          </label>
        </div>
        <div className="buttons">
          <Button type="primary" onClick={handleSave} className="save-btn">Save</Button>
          <Button onClick={() => setDistributorData({ distributorName: "", userId: "", contactNo: "", address: "", state: "", gstin: "", active: false })} className="reset-btn">Reset</Button>
        </div>
      </div>
      <div className="table-container">
        <div className="table-header">View Distributor</div>
        <Table dataSource={distributors} columns={columns} rowKey="userId" />
      </div>
    </div>
  );
};

export default VendorManagement;
