// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AdminLoginPage.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
  }
  
  .login-card {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 350px;
  }
  
  .login-heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .login-subheading {
    text-align: center;
    font-size: 14px;
    color: #888;
    margin-bottom: 20px;
  }
  
  .form-field {
    margin-bottom: 15px;
  }
  
  .form-label {
    font-size: 14px;
    color: #555;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:disabled {
    background-color: #ccc;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Pages/Login.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,WAAW;IACX,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,UAAU;IACV,eAAe;IACf,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":["/* AdminLoginPage.css */\n\n.login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f4f4f9;\n  }\n  \n  .login-card {\n    background-color: white;\n    padding: 30px;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    width: 350px;\n  }\n  \n  .login-heading {\n    text-align: center;\n    font-size: 24px;\n    margin-bottom: 10px;\n  }\n  \n  .login-subheading {\n    text-align: center;\n    font-size: 14px;\n    color: #888;\n    margin-bottom: 20px;\n  }\n  \n  .form-field {\n    margin-bottom: 15px;\n  }\n  \n  .form-label {\n    font-size: 14px;\n    color: #555;\n  }\n  \n  .form-input {\n    width: 100%;\n    padding: 10px;\n    font-size: 16px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    margin-top: 5px;\n  }\n  \n  .login-button {\n    width: 100%;\n    padding: 12px;\n    background-color: #4caf50;\n    color: white;\n    font-size: 16px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .login-button:disabled {\n    background-color: #ccc;\n  }\n  \n  .error-message {\n    color: red;\n    font-size: 14px;\n    text-align: center;\n    margin-top: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
